import { createI18n } from "vue-i18n";
import type {
  IntlDateTimeFormat,
  IntlDateTimeFormats,
  IntlNumberFormats,
} from "vue-i18n";

import enProject from "@/locales/en.json";
import enCore from "@devsalsa/vue-core/dist/locales/en.json";
import { MarketplaceHandler } from "@/core/shared/helpers/MarketplaceHandler";

const setDateTimeFormats: IntlDateTimeFormat = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  numeric: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  numericZeros: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  time: {
    hour: "numeric",
    minute: "numeric",
  },
  long: {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  },
  monthWithDay: {
    month: "long",
    day: "numeric",
  },
  monthDayYear: {
    month: "long",
    day: "numeric",
    year: "numeric",
  },
  monthYear: {
    month: "long",
    year: "numeric",
  },
};

const datetimeFormats: IntlDateTimeFormats = {
  en: setDateTimeFormats,
};

const numberFormats: IntlNumberFormats = {
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-CA": {
    currency: {
      style: "currency",
      currency: "CAD",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-GB": {
    currency: {
      style: "currency",
      currency: "GBP",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-AU": {
    currency: {
      style: "currency",
      currency: "AUD",
      currencyDisplay: "symbol",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: MarketplaceHandler.getLocale() || "en",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    en: { ...enProject, ...enCore },
  },
  datetimeFormats,
  numberFormats,
});
