<template>
  <Transition name="notification">
    <div
      ref="toastRef"
      v-if="!close"
      :class="['app-toast', `app-toast-${themeColor}`]"
      @mouseenter="pausedTimer(true)"
      @mouseleave="pausedTimer(false)"
      data-testid="app-toast-notification"
    >
      <div class="app-toast-container">
        <!-- image -->
        <DsImageCloud
          :src="message.image_url"
          :alt="message.title"
          class="app-toast-image"
        />
        <!-- message -->
        <div
          :class="[
            'app-toast-container-message',
            { 'app-toast-container-message-max-w': enableClose },
          ]"
          @click="onClick"
        >
          <p
            class="app-toast-notification-title"
            data-testid="app-toast-notification-title"
          >
            {{ message.title }}
          </p>
          <p
            v-html="message.message"
            class="app-toast-notification-message"
            data-testid="app-toast-notification-message"
          />
        </div>
        <!-- close -->
        <span
          v-if="enableClose"
          class="app-toast-notification-close"
          @click="onClose"
          data-testid="app-toast-notification-close"
        >
          <DsCloseOutlineIcon class="app-toast-notification-close-icon" />
        </span>
      </div>
      <!-- animation -->
      <div v-if="duration > 0" class="app-toast-progress-bar-wrap">
        <div
          :class="[
            'app-toast-progress-bar',
            `app-toast-progress-bar-${themeColor}`,
            {
              'app-toast-animation-paused': isPaused,
              'app-toast-animation-resume': !isPaused,
            },
          ]"
          :style="animation"
        />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import { getUnixTime } from "date-fns";
import type { PropType } from "vue";
import { useRouter } from "vue-router";

import { DsCloseOutlineIcon, DsImageCloud } from "@devsalsa/vue-core";

import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

import AppNotifier from "@/core/shared/helpers/Notifier/AppNotifier";
import NotificationRouterLink from "@/modules/notification/helpers/NotificationRouterLink";

defineOptions({
  name: "AppToast",
});

const props = defineProps({
  unique: {
    type: Number,
    required: true,
  },
  message: {
    type: Object as PropType<AppNotification>,
    required: true,
  },
  themeColor: {
    type: String,
    required: false,
    default: "blue",
  },
  duration: {
    type: Number,
    default: 10,
  },
  enableClose: {
    type: Boolean,
    default: true,
  },
});

const router = useRouter();

const toastRef = ref<HTMLDivElement | null>(null);
const close = ref(false);
const isPaused = ref(false);

const timer = reactive({
  id: 0,
  rest: 0,
  start: 0,
  pause: 0,
});

const animation = computed((): string => {
  return `animation: ${props.duration}s linear app-toast-theme-color, ${props.duration}s ease-out app-toast-enter forwards`;
});

onMounted(() => {
  const instance = getCurrentInstance();

  timer.start = getUnixTime(new Date());
  timer.pause = timer.start;
  timer.rest = props.duration;

  if (props.duration > 0) {
    timer.id = timerClock();
  }

  if (instance?.parent) {
    const previousToast: HTMLDivElement =
      instance.parent.proxy?.$el.firstElementChild;
    previousToast.insertAdjacentElement(
      "beforebegin",
      toastRef.value as HTMLDivElement
    );
  }
});

function onClose(): void {
  close.value = true;
  AppNotifier.deleteStoreNotification(props.unique);
}
function onClick(): void {
  router.push(NotificationRouterLink.get(props.message));
}
function timerClock(): number {
  return window.setTimeout(() => {
    onClose();
  }, timer.rest * 1000);
}
function pausedTimer(flag: boolean): void {
  //Don't manage timer if set duration equal to 0
  if (props.duration === 0) {
    return;
  }

  isPaused.value = flag;
  if (isPaused.value) {
    clearTimeout(timer.id);
    timer.pause = getUnixTime(new Date()) - timer.start;
    timer.rest = timer.rest - timer.pause;
  } else {
    timer.start = getUnixTime(new Date());
    timer.id = timerClock();
  }
}
</script>

<style lang="scss">
@keyframes app-toast-theme-color {
  100% {
    background-position: 0;
  }

  0% {
    background-position: 100%;
  }
}

@keyframes app-toast-enter {
  100% {
    transform: scaleX(0);
  }
  0% {
    transform: scaleX(1);
  }
}
</style>

<style lang="scss" scoped>
.app-toast {
  @apply h-auto border-l-2 p-4 rounded-r-md bg-white shadow-jb flex flex-col cursor-pointer max-w-[300px] md:max-w-md z-30;

  &-image {
    @apply w-16 h-16 object-scale-down rounded-md;

    :deep(.ds-lazy-image) {
      @apply h-full;
    }
  }

  &-container {
    @apply flex gap-x-4 pr-6 relative w-full;

    &-message {
      @apply space-y-1 flex flex-col text-left max-w-xs break-words line-clamp-6;

      &-max-w {
        @apply max-w-[calc(100%_-_88px)];
      }

      :deep(a) {
        @apply text-blue-600 hover:underline block mt-2 text-sm;
      }
    }
  }

  &-blue {
    @apply border-l-blue-500;
  }

  &-green {
    @apply border-l-green-500;
  }

  &-yellow {
    @apply border-l-yellow-500;
  }

  &-red {
    @apply border-l-red-500;
  }

  &-gray {
    @apply border-l-gray-500;
  }

  &-notification {
    &-title {
      @apply text-sm font-medium text-gray-900;
    }

    &-message {
      @apply break-words line-clamp-6 font-normal text-sm text-gray-500;
    }

    &-close {
      @apply cursor-pointer absolute top-0 right-0 text-gray-500 w-5;

      &-icon {
        @apply h-5 w-5;
      }
    }
  }

  &-progress-bar {
    @apply bg-gray-300 h-0.5 origin-right;

    &-wrap {
      @apply w-auto bg-gray-200 mt-2;
    }

    &-blue {
      @apply bg-gradient-to-r from-blue-600 to-blue-300;
    }

    &-green {
      @apply bg-gradient-to-r from-green-600 to-green-300;
    }

    &-yellow {
      @apply bg-gradient-to-r from-yellow-600 to-yellow-300;
    }

    &-red {
      @apply bg-gradient-to-r from-red-500 to-red-300;
    }
  }
}

.app-toast-animation-paused {
  animation-play-state: paused !important;
}

.app-toast-animation-resume {
  animation-play-state: running !important;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
}

.notification-enter-active,
.notification-leave-active {
  transition: opacity 0.5s ease;
}
</style>
