import { ApiService } from "@/core/shared/services/ApiService";
import type {
  CampaignCreated,
  CampaignStats,
  CampaignUpdated,
  JobCalculationData,
  CampaignCreationFormData,
  CampaignCalculationBlastJobs,
  CampaignInviteLimits,
} from "@/modules/campaign/services/CampaignService.types";
import type { UgcTypeName } from "@/shared/enums/UgcTypeEnum";
import type { CampaignExtended } from "@/shared/services/SearchService.types";

export default class CampaignService {
  /**
   * @param data
   * @param ugcType
   */
  static create(
    data: CampaignCreationFormData,
    ugcType: UgcTypeName
  ): Promise<CampaignCreated> {
    return ApiService.post(`/company/campaigns/${ugcType}`, data);
  }

  /**
   * @param campaignId
   */
  static get(campaignId: number): Promise<CampaignExtended> {
    return ApiService.get(`/company/campaigns/${campaignId}`);
  }

  /**
   * @param data
   */
  static getJobCalculation(data: FormData): Promise<JobCalculationData> {
    return ApiService.post("/company/campaigns/cost-calculation", data);
  }

  /**
   *
   * @param campaignId
   */
  static pause(campaignId: number): Promise<CampaignUpdated> {
    return ApiService.put(`/company/campaigns/${campaignId}/pause`);
  }

  /**
   *
   * @param campaignId
   */
  static unpause(campaignId: number): Promise<CampaignUpdated> {
    return ApiService.put(`/company/campaigns/${campaignId}/unpause`);
  }

  /**
   * @endpoint /campaign/campaign_id/stats
   * @httpMethod GET
   * @param campaignId
   */
  static stats(campaignId: number): Promise<CampaignStats> {
    return ApiService.get(`/company/campaigns/${campaignId}/stats`);
  }

  static addJobs(
    campaignId: number,
    maxJobs: number
  ): Promise<CampaignUpdated> {
    return ApiService.put(`/company/campaigns/${campaignId}/max-jobs`, {
      max_jobs: maxJobs,
    });
  }
  static addBlastJobs(
    campaignId: number,
    creditCardId: number,
    nrJobs: number
  ): Promise<CampaignUpdated> {
    return ApiService.put(
      `/company/campaigns/${campaignId}/increase-jobs-to-bundle-campaign`,
      {
        credit_card_id: creditCardId,
        nr_of_jobs: nrJobs,
      }
    );
  }

  static calculateBlastJobs(
    campaignId: number,
    nrJobs: number
  ): Promise<CampaignCalculationBlastJobs> {
    return ApiService.post(
      `/company/campaigns/${campaignId}/calculated-increase-bundle-jobs`,
      {
        nr_of_jobs: nrJobs,
      }
    );
  }

  /**
   * @endpoint /campaign/campaign_id/creator-limits
   * @httpMethod GET
   * @param campaignId
   */
  static getInviteLimits(campaignId: number): Promise<CampaignInviteLimits> {
    return ApiService.get(`/company/campaigns/${campaignId}/creator-limits`);
  }
}
